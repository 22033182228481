import React, { useEffect, useState } from "react";
import "./scss/App.scss";
import axios, { AxiosError, AxiosResponse } from "axios";
import Loader from "./components/Loader";
import CpmForm from "./components/CpmForm";
import {
  DealerProps,
  TranslationProps,
  ResponseProps,
  SupportedLanguages,
} from "./App.d";
import UnsubscribeForm from "./components/UnsubscribeForm";

export enum CPM_LEVEL {
  DEALER = "dealer",
  CARRIER = "carrier",
}

export enum CPM_CHANNEL {
  SMS = "sms",
  CALLING = "call",
  ALL = "all",
}

export enum DEALER_FEATURES {
  HIDE_AUTHORIZED_DEALER = 206,
  CPM_CASL = 208,
  INDEPENDENT_OPERATOR = 210,
  CASL_CPM_OPTOUT = 211,
}

const App = () => {
  const [formType, setFormType] = useState<"non-cpm" | "cpm">("non-cpm");
  const [formState, setFormState] = useState<string>("message");
  const [shortCode, setShortCode] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [currentLang, setCurrentLang] = useState<string>("en");
  const [optionalLang, setOptionalLang] = useState<SupportedLanguages>();
  const [dealerName, setDealerName] = useState<string | null>(null);
  const [dealer, setDealer] = useState<DealerProps | null>(null);
  const [translation, setTranslations] = useState<TranslationProps | null>(
    null
  );
  const [brandLogoPath, setBrandLogoPath] = useState<string>();
  const [tagLine, setTagLine] = useState<string | null>(null);
  const [campaign, setCampaign] = useState<string | null>(null);
  const [formError, setFormError] = useState<string | null>(null);
  const [cpmLevel, setCpmLevel] = useState<string | null>(null);
  const [cpmChannel, setCpmChannel] = useState<string | null>(null);
  const [tollFreeNumber, setTollFreeNumber] = useState<string | null>(null);
  const [contactId, setContactId] = useState<number | null>(null);

  useEffect(() => {
    const code = parseShortCode();
    setShortCode(code);
    loadDealer(code);
  }, []);

  const parseShortCode = () => {
    // strip out the domain and trailing /, then non-word characters (like ?)
    return document.location.href
      .replace(document.location.origin + "/", "")
      .replace(/\W+/g, "");
  };

  const loadDealer = async (
    shortCode: string | null,
    language: string | null = null
  ) => {
    setLoading(true);

    let url = `${process.env.REACT_APP_PUBLIC_HOST}v1/sender/${shortCode}`;

    if (language) {
      url += `?language=${language}`;
    }

    const resp = await axios(url).then(
      (resp: AxiosResponse<ResponseProps>) => resp.data
    );

    if (resp.brandMetadata) {
      setBrandLogoPath(resp.brandMetadata.value);
    }

    if (resp.carrierMetadata) {
      setTollFreeNumber(resp.carrierMetadata.toll_free_number);
    }

    if (resp.contactId) {
      setContactId(resp.contactId);
    }

    if (resp.dealer) {
      const outlet: any = resp.outlet;
      const requiredFields = [
        "name",
        "address_1",
        "city",
        "province",
        "country",
        "postal_code",
      ];
      const useOutlet = resp.outlet
        ? requiredFields.filter((item: any) => outlet[item]).length ===
          requiredFields.length
        : false;

      document.title = resp.dealer.name;

      setDealerName(resp.dealer.name);
      setDealer(useOutlet ? { ...resp.dealer, ...resp.outlet } : resp.dealer);
    }

    if (resp.translations) {
      setCurrentLang(resp.translations.current);
      setOptionalLang(resp.translations.optional);
      setTranslations(resp.translations.translation);

      if (
        !resp.dealerAllowedFeatures.includes(
          DEALER_FEATURES.HIDE_AUTHORIZED_DEALER
        )
      ) {
        const tagLine =
          resp.translations.translation.casl_authorized_dealer ||
          ":carrier_name: dealer";
        setTagLine(tagLine.replace(":carrier_name:", resp.dealer.carrier));
      }

      if (
        resp.dealerAllowedFeatures.includes(
          DEALER_FEATURES.INDEPENDENT_OPERATOR
        )
      ) {
        const tagLine =
          resp.translations.translation.casl_independent ||
          " :carrier_name: independent operator";
        setTagLine(tagLine.replace(":carrier_name:", resp.dealer.carrier));
      }
    }

    if (resp.campaign) {
      setCampaign(resp.campaign);
    }

    if (resp.dealerAllowedFeatures.includes(DEALER_FEATURES.CASL_CPM_OPTOUT)) {
      setFormType("cpm");
      setFormState("cpm");
    }

    setLoading(false);
  };

  const renderIframe = (campaign: any = "") => {
    if (!campaign || campaign === "") {
      return;
    }
    return (
      <iframe
        className="promoContent"
        frameBorder="0"
        title="promo"
        src={campaign}
      ></iframe>
    );
  };

  const changeLanguage = (language: string) => {
    loadDealer(shortCode, language);
  };

  const submitDNCForm = async (number: string) => {
    setFormState("unsubscribing");
    setFormError(null);

    const url = `${process.env.REACT_APP_WEBAPP_HOST}v2/api/dnc`;

    const stripped = number.replace(/\D/g, "");

    const requestBody = {
      dealer_id: dealer?.id,
      phone_number: `+1${stripped}`,
      contact_id: contactId,
      ...(formState === "cpm" && { type: cpmLevel }),
      ...(formState === "cpm" && { channel: cpmChannel }),
    };
    const resp = await axios.post(url, requestBody);
    return resp;
  };

  const getErrorMessage = () => {
    switch (currentLang) {
      case "en":
        return "Oops! We’re running into an issue. To ensure your preferences have been saved, please reply STOP to the message you received.";
      case "cmn":
        return "Oops! We’re running into an issue. To ensure your preferences have been saved, please reply STOP to the message you received.";
      case "es":
        return "ES: ¡Ups! Detectamos un problema. Para asegurarte de que tus preferencias se hayan guardado, responde STOP al mensaje que recibiste.";
      case "fr":
        return "Oups ! Nous rencontrons un problème. Pour vous assurer que vos préférences ont été enregistrées, veuillez répondre STOP au message que vous avez reçu.";
      case "yue":
        return "Oops! We’re running into an issue. To ensure your preferences have been saved, please reply STOP to the message you received.";
      default:
        return "Oops! We’re running into an issue. To ensure your preferences have been saved, please reply STOP to the message you received.";
    }
  };

  const handleSuccess = (res: AxiosResponse<any>) => {
    if (res.data && res.status === 200) {
      setFormState("unsubscribed");
      setUnsubscribedCookie("unsubscribed", "true");
      return;
    }
    setFormState("error");
    setFormError(getErrorMessage());
  };

  const handleError = (error: AxiosError) => {
    if (error && error.response && error.response.status === 400) {
      setFormState(formType === "cpm" ? "cpm" : "form");
      const message =
        translation && translation.casl_unregistered_phone_number
          ? translation.casl_unregistered_phone_number
          : null;
      setFormError(message);
      return;
    }
    setFormState("error");
    setFormError(getErrorMessage());
  };

  const handleUnsubscribe = (number: string) => {
    submitDNCForm(number)
      .then((res: AxiosResponse) => {
        handleSuccess(res);
      })
      .catch((err: AxiosError) => {
        handleError(err);
      });
  };

  const setUnsubscribedCookie = (cname: string, cvalue: string) => {
    var d = new Date();
    d.setTime(d.getTime() + 365 * 24 * 60 * 60 * 1000);
    var expires = "expires=" + d.toUTCString();

    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  };

  const handleCancel = () => {
    setFormState("message");
    setFormError(null);
  };

  const unsubscribeForm = (translation: TranslationProps) => {
    switch (formState) {
      case "form":
        return (
          <UnsubscribeForm
            error={formError}
            cancelLabel={translation.cancel}
            numberLabel={translation.casl_enter_your_number}
            unsubscribeLabel={translation.unsubscribe}
            onCancel={() => handleCancel()}
            onUnsubscribe={(number) => handleUnsubscribe(number)}
          />
        );
      case "cpm":
        return (
          <>
            {formError && <div className="submitErrorMessage">{formError}</div>}
            <CpmForm
              cancelLabel={translation.cancel}
              numberLabel={translation.casl_enter_your_number}
              unsubscribeLabel={translation.unsubscribe}
              carrierName={dealer && dealer.carrier}
              setCpmLevel={setCpmLevel}
              setCpmChannel={setCpmChannel}
              setFormError={setFormError}
              onUnsubscribe={(number) => handleUnsubscribe(number)}
              translations={translation}
            />
          </>
        );
      case "unsubscribing":
        return (
          <p className="smallParagraph">
            {translation.casl_unsubscribing}...{" "}
            <span aria-label="cry emoji" role="img">
              😭
            </span>
          </p>
        );
      case "unsubscribed":
        return (
          <p className="smallParagraph">
            {translation.casl_unsubscribed}.{" "}
            <span aria-label="check emoji" role="img">
              ✅
            </span>
          </p>
        );
      default:
        return (
          <>
            {formError && <div className="submitErrorMessage">{formError}</div>}
            <p className="smallParagraph">
              {translation.casl_stop_1} <strong>{translation.casl_stop}</strong>{" "}
              {translation.or}{" "}
              <button
                onClick={() =>
                  setFormState(formType === "cpm" ? "cpm" : "form")
                }
              >
                {translation.casl_stop_2}.
              </button>
            </p>
          </>
        );
    }
  };

  if (loading) {
    return <Loader />;
  }

  const getLanguageLinks = () => {
    let languageLinks = [];
    for (let key in optionalLang) {
      languageLinks.push(
        <button
          key={key}
          className="languageButton"
          onClick={() => changeLanguage(key)}
        >
          {optionalLang[key]}{" "}
        </button>
      );
    }
    return languageLinks;
  };

  if (!loading && translation && dealer) {
    return (
      <div className="pageWrapper">
        <div className="logo">
          <img alt="Carrier Logo" src={brandLogoPath} />
        </div>
        <div className="topLanguagesLinks"> {getLanguageLinks()}</div>
        <div className="container text-center">
          <h1 className="headline">
            {translation.casl_hi}!{" "}
            <span aria-label="wave emoji" role="img">
              👋
            </span>
          </h1>
          <p>{translation.casl_headline}</p>
        </div>
        {renderIframe(campaign)}
        <div className="container text-center">
          <h6 className="killBottomMargin">
            {translation.casl_business_name}:
          </h6>
          <h1 className="killBottomMargin killTopMargin">{dealerName}</h1>
          <h5 className="killTopMargin">{tagLine}</h5>
          <p>
            <span aria-label="phone emoji" role="img">
              📞
            </span>
            &nbsp; {dealer.phone}
          </p>
          {tollFreeNumber && (
            <p>
              <span>{translation.casl_toll_free}</span>&nbsp; {tollFreeNumber}
            </p>
          )}
          <p>
            <span aria-label="store emoji" role="img">
              🏬
            </span>
            &nbsp; {dealer.address_1}
            <br />
            {dealer.address_2}
            <br />
            {dealer.city}, {dealer.province}
            <br />
            {dealer.postal_code}
          </p>
        </div>
        <div className="container text-center">
          {unsubscribeForm(translation)}
        </div>
      </div>
    );
  }

  return null;
};

export default App;
